<template>
  <div>
    <!-- banner -->
    <div class="banner" :style="cover"></div>
    <!-- 归档列表 -->
    <v-card class="blog-container">
      <label>
        <input
          type="file"
          v-show="false"
          accept="text/plain"
          @change="uploadFiles"
        />
        <div
          class="uploadIcon"
          @dragover="$event.preventDefault()"
          @dragenter="$event.preventDefault()"
          @drop="dropEvent"
        >
          <p
            class="iconfont icon-text"
            style="
        color: rgb(150, 149, 149);
        font-size: 6rem;"
          >
            >
          </p>
          <p>将文件拖拽到此处即可完成文件上传。</p>
        </div>
      </label>
      <timeline>
        <timeline-title>
          <label style="font-size: 17px; color: gray; cursor: pointer;">
            <input
              type="file"
              v-show="false"
              accept="text/plain"
              @change="uploadFiles"
            />
            <span class="mr-3 iconfont icon-caidao"></span>
            历史战况-><a
              style="color: blue; cursor: pointer;"
              href="https://sun.pecs.cn/"
              >【使用新版】</a
            >
          </label>
        </timeline-title>
        <timeline-item v-for="item of archiveList" :key="item.id">
          <!-- 日期 -->
          <span class="time">{{
            item.createdAt.substring(0, 19).replace("T", " ")
          }}</span>
          <!-- 文章标题 -->
          <router-link
            :to="'/battles/' + item.id"
            style="color:#666;text-decoration: none"
          >
            <div>
              <span>{{ item.id }}</span
              ><span class="separator">|</span
              ><span style="color: gray;">{{ item.fileName }}</span>
            </div>
          </router-link>
        </timeline-item>
      </timeline>
      <!-- 分页按钮 -->
      <v-pagination
        color="#00C4B6"
        v-model="current"
        :length="Math.ceil(count / 10)"
        total-visible="7"
      />
    </v-card>
  </div>
</template>

<script>
import { Timeline, TimelineItem, TimelineTitle } from "vue-cute-timeline";
export default {
  created() {
    this.listArchives();
  },
  components: {
    Timeline,
    TimelineItem,
    TimelineTitle
  },
  data: function() {
    return {
      current: 1,
      count: 0,
      archiveList: []
    };
  },
  methods: {
    success (notification) {
      this.mode = 'success'
      this.show(notification)
    },
    listArchives() {
      this.axios
        .get("/api/sun-battle-file/listBattleFiles", {
          params: { current: this.current }
        })
        .then(({ data }) => {
          this.archiveList = data.data.recordList;
          this.count = data.data.count;
          // this.$myMsg.notify({ content: '加载成功', type: 'success', noNotifyBtn: false, time: 1000 });
        });
    },
    uploadFiles(e) {
      const file = e.target.files[0];
      this.upload(file);
    },
    upload(file) {
      const formData = new FormData();
      formData.append("file", file);
      this.axios
        .request({
          method: "post",
          headers: {
            "Content-Type": "multipart-formData"
          },
          url: "/api/sun-battle-file/upload",
          data: formData
        })
        .then(() => {
          // alert(data.message);
          // this.$refs.notification.success(data.message);
          this.$myMsg.notify({ content: '上传成功', type: 'success', noNotifyBtn: false, time: 1000 });
          window.location.reload();
        });
    },
    dropEvent(e) {
        e.preventDefault()
        let { files } = e.dataTransfer
        for (let i = 0; i < files.length; i++)
            this.upload(files[i])
    }
  },
  computed: {
    cover() {
      var cover = "";
      this.$store.state.blogInfo.pageList.forEach(item => {
        if (item.pageLabel == "sun") {
          cover = item.pageCover;
        }
      });
      return "background: url(" + cover + ") center center / cover no-repeat";
    }
  },
  watch: {
    current(value) {
      this.axios
        .get("/api/sun-battle-file/listBattleFiles", {
          params: { current: value }
        })
        .then(({ data }) => {
          this.archiveList = data.data.recordList;
          this.count = data.data.count;
        });
    }
  }
};
</script>

<style scoped>
.uploadIcon {
  margin: 0 auto;
  width: 80%;
  height: 250px;
  text-align: center;
  cursor: pointer;
  border: 5px dashed rgb(150, 149, 149);
}
.time {
  font-size: 0.75rem;
  color: #555;
  margin-right: 1rem;
}
</style>
